import { FRONTEGG_SIGNUP_ENDPOINT, JSON_HEADERS } from './constants'
import { IS_PRODUCTION } from '@dtx-company/true-common/src/constants/env'
import { SignUpLocation } from '../auth'
import { logger } from '@dtx-company/logger'
import { login } from './login'
import { notifyObserversOfSignup } from './notify'
import { setAccessTokenCookie } from './setAccessTokenCookie'
import activateUser from './activateUser'

export const USER_ALREADY_EXISTS = 'User already exists'
export const USER_ALREADY_EXISTS_CODE = 'ER-01086'
export const INCORRECT_EMAIL_OR_PASSWORD = 'Incorrect email or password'

export interface SignUpProps {
  email: string
  provider?: string
  cohort: string
  password: string
  invitationToken?: string
  product: string | null
  skipInviteEmail?: boolean
  recaptchaToken?: string
  signupLocation: SignUpLocation
  shouldForceActivate: boolean
}

export async function signupWithEmailAndPassword({
  email,
  password,
  cohort,
  invitationToken,
  provider = 'local',
  product,
  skipInviteEmail = true,
  recaptchaToken,
  signupLocation,
  shouldForceActivate = false
}: SignUpProps): Promise<{
  errors: string[]
  shouldActivate: boolean
  accessToken: string | undefined
}> {
  try {
    const res = await fetch(FRONTEGG_SIGNUP_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        provider,
        skipInviteEmail,
        invitationToken,
        companyName: `My Team - ${email}`,
        // Be careful to keep the metadata slim since it becomes part of the jwt and
        // is included in cookies and request headers
        metadata: JSON.stringify({
          cohort,
          platformSix: false
        }),
        recaptchaToken
      }),
      credentials: 'include',
      headers: JSON_HEADERS
    })

    const signupResponse = await res.json()

    const errors: string[] = signupResponse.errors ?? []
    const shouldActivate = Boolean(signupResponse?.shouldActivate)
    const userId: string | undefined = signupResponse?.userId
    const signupAccessToken: string | undefined = signupResponse?.authResponse?.accessToken

    const userQualifiesForFlowdtxBypass =
      shouldActivate && userId && email.includes('@flowdtx.com') && !IS_PRODUCTION

    const userQualifiesForForceActivation = shouldActivate && userId && shouldForceActivate

    // flowtag, canva, and @flowdtx.com emails
    if (userQualifiesForFlowdtxBypass || userQualifiesForForceActivation) {
      const { accessToken: activateUserAccessToken } = await activateUser(userId)

      if (activateUserAccessToken) {
        await setAccessTokenCookie(activateUserAccessToken)
        notifyObserversOfSignup(activateUserAccessToken, product || undefined, signupLocation)

        return {
          errors: [],
          shouldActivate: false,
          accessToken: activateUserAccessToken
        }
      }
    }

    if (errors.length) {
      const userAlreadyExists =
        errors.includes(USER_ALREADY_EXISTS) ||
        signupResponse.errorCode === USER_ALREADY_EXISTS_CODE

      const incorrectEmailOrPassword = errors.includes(INCORRECT_EMAIL_OR_PASSWORD)

      if (userAlreadyExists && !incorrectEmailOrPassword) {
        const {
          shouldActivate: shouldActivateFromLogin,
          errors: errorsFromLogin,
          accessToken: accessTokenFromLogin
        } = await login({
          email,
          password,
          invitationToken,
          recaptchaToken
        })

        return {
          shouldActivate: shouldActivateFromLogin,
          errors: [...errors, ...errorsFromLogin],
          accessToken: accessTokenFromLogin
        }
      }
    }

    if (signupAccessToken) {
      await setAccessTokenCookie(signupAccessToken)
      notifyObserversOfSignup(signupAccessToken, product || undefined, signupLocation)
    }

    return { shouldActivate, errors, accessToken: signupAccessToken }
  } catch (e) {
    logger.logError(e, { team: 'PLG' })

    return { shouldActivate: false, errors: [], accessToken: undefined }
  }
}
