import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { logger } from '@dtx-company/logger'

const sendActivationEmail = async (email: string): Promise<boolean> => {
  try {
    const res = await fetch(`${FLOWCODE_ROOT}/api/frontegg/reset-activation`, {
      method: 'POST',
      body: JSON.stringify({
        email
      })
    })

    if (res.ok || res.status === 201) return true
  } catch (e) {
    logger.logError(`${e}`, { team: 'PLG' })
  }

  return false
}

export default sendActivationEmail
