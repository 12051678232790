import { FRONTEGG_LOGIN_ENDPOINT, JSON_HEADERS } from './constants'
import { IS_PRODUCTION } from '@dtx-company/true-common/src/constants/env'
import { ServerSideError, ServerSideErrorType, logger } from '@dtx-company/logger'
import { notifyObserversOfLogin } from './notify'
import { setAccessTokenCookie } from './setAccessTokenCookie'
import activateUser from './activateUser'
import sendActivationEmail from './sendActivationEmail'

export const USER_IS_NOT_VERIFIED = 'User is not verified'
export const USER_IS_NOT_VERIFIED_ERROR_CODE = 'ER-01090'

export async function login({
  email,
  password,
  invitationToken,
  recaptchaToken
}: {
  email: string
  password: string
  invitationToken?: string
  recaptchaToken?: string
}): Promise<{ errors: string[]; shouldActivate: boolean; accessToken: string | undefined }> {
  try {
    const res = await fetch(FRONTEGG_LOGIN_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        invitationToken,
        recaptchaToken
      }),
      credentials: 'include',
      headers: JSON_HEADERS
    })

    const loginResponse = await res.json()

    const errors: string[] = loginResponse.errors ?? []
    const shouldActivate = Boolean(loginResponse?.shouldActivate)
    const userId: string | undefined = loginResponse?.userId
    const accessToken: string | undefined = loginResponse?.accessToken

    const userQualifiesForFlowdtxBypass =
      shouldActivate && userId && email.includes('@flowdtx.com') && !IS_PRODUCTION

    // workaround for existing @flowdtx.com emails
    if (userQualifiesForFlowdtxBypass) {
      const { accessToken: activateUserAccessToken } = await activateUser(userId)

      if (activateUserAccessToken) {
        await setAccessTokenCookie(activateUserAccessToken)
        notifyObserversOfLogin(activateUserAccessToken)

        return {
          errors: [],
          shouldActivate: false,
          accessToken: activateUserAccessToken
        }
      }
    }

    if (errors.length) {
      logger.logError(
        new ServerSideError({
          name: 'signin',
          message: errors?.[0],
          endpoint: 'signin',
          type: ServerSideErrorType.ServerSideValidationError
        }),
        { technicalArea: 'authentication' }
      )

      if (
        errors.includes(USER_IS_NOT_VERIFIED) ||
        loginResponse.errorCode === USER_IS_NOT_VERIFIED_ERROR_CODE
      ) {
        await sendActivationEmail(email)
      }

      return { shouldActivate, errors, accessToken }
    }

    if (accessToken) {
      await setAccessTokenCookie(accessToken)
      notifyObserversOfLogin(accessToken)
    }

    return { shouldActivate, errors, accessToken }
  } catch (e) {
    return { shouldActivate: false, errors: [e.message], accessToken: undefined }
  }
}
