import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { logger } from '@dtx-company/logger'

const activateUser = async (userId: string): Promise<{ accessToken: string | null }> => {
  try {
    const res = await fetch(`${FLOWCODE_ROOT}/api/frontegg/manual-activation`, {
      method: 'POST',
      body: JSON.stringify({
        userId
      })
    })

    if (res.ok || res.status === 201) {
      const { accessToken } = await res.json()
      return { accessToken }
    }
  } catch (e) {
    logger.logError(`${e}`, { team: 'PLG' })
  }

  return { accessToken: null }
}

export default activateUser
