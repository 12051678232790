import { User, login } from '@dtx-company/ithaca-sdk/src'
import {
  auth_signin_form_emailpassword_failure,
  auth_signin_form_emailpassword_started,
  auth_signin_form_emailpassword_success
} from '../../constants/authHeapEvents'
import { fireAnalyticsEvent } from '../../event-tracking/helpers/fireAnalyticsEvent'
import { logger } from '@dtx-company/logger'

export interface UseLoginProps {
  values: {
    email: string
    password: string
  }
}

export function useLogin(): ({
  values
}: UseLoginProps) => Promise<{ user: User; errors: string[] }> {
  return async ({ values }) => {
    try {
      const { email, password } = values

      fireAnalyticsEvent(auth_signin_form_emailpassword_started)

      const { errors, shouldActivate } = await login({ email, password })

      fireAnalyticsEvent(auth_signin_form_emailpassword_success)

      const user: User = { email, shouldActivate }

      return { user, errors }
    } catch (e) {
      fireAnalyticsEvent(auth_signin_form_emailpassword_failure)

      logger.logError(e, { team: 'PLG' })

      return { user: { email: null, shouldActivate: false }, errors: [] }
    }
  }
}
