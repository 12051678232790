import { ActivationState } from '../../EmailVerification'
import { AuthenticationFormFields } from '../AuthenticationForm.types'
import { Domains } from '@dtx-company/true-common/src/types/domains'
import { EMAIL_VERIFICATION_COPY } from '../../../constants/emailVerification'
import {
  INCORRECT_EMAIL_OR_PASSWORD,
  USER_ALREADY_EXISTS,
  USER_IS_NOT_VERIFIED
} from '@dtx-company/ithaca-sdk/src'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SetStateAction, useCallback } from 'react'
import { clearAuthFormState } from '../../../redux/slices/auth-form'
import { fireAnalyticsEvent } from '../../../event-tracking/helpers/fireAnalyticsEvent'
import { logger } from '@dtx-company/logger'
import { useDispatch } from 'react-redux'
import { useLogin } from '../../../hooks/auth/useLogin'
import { useRouter } from 'next/router'
import { useSignup } from '../../../hooks/auth/useSignup'

export interface OnAuthFormSubmitProps {
  values: AuthenticationFormFields
  setErrorAlert: (value: SetStateAction<string[]>) => void
  handleGeneratorCodeCreation: () => Promise<void>
  setShowActivationBanner: (value: SetStateAction<ActivationState | null>) => void
  setIsSubmitDisabled: (value: SetStateAction<boolean>) => void
  setResendEmail: (value: SetStateAction<string | undefined>) => void
}

export function useOnAuthFormSubmit(): ({
  values,
  setErrorAlert,
  handleGeneratorCodeCreation,
  setShowActivationBanner,
  setIsSubmitDisabled,
  setResendEmail
}: OnAuthFormSubmitProps) => Promise<void> {
  const dispatch = useDispatch()
  const login = useLogin()
  const signup = useSignup()
  const { pathname, push } = useRouter()

  const isSignIn = pathname === Routes.SIGN_IN
  const isSignUp = pathname === Routes.SIGN_UP
  const isFreeQrGeneratorPage = pathname === Routes.FREE_QR_CODE_GENERATOR

  return useCallback(
    async ({
      values,
      setErrorAlert,
      handleGeneratorCodeCreation,
      setShowActivationBanner,
      setIsSubmitDisabled,
      setResendEmail
    }) => {
      // isSubmitDisabled only active in tandem w/ email verification to prevent email spam, 30s lockout

      try {
        if (isSignUp) {
          const { user, errors } = await signup({
            values,
            product: Domains.FLOWCODE
          })

          // signup success, needs verified, green banner
          if (user.shouldActivate) {
            setIsSubmitDisabled(true)
            setShowActivationBanner(ActivationState.SIGNUP)
            fireAnalyticsEvent('userViewedActivationStateSignup', {
              copyShownToUser: EMAIL_VERIFICATION_COPY.signup
            })
          }
          // auth success, needs verified, yellow banner
          else if (errors.includes(USER_IS_NOT_VERIFIED)) {
            setIsSubmitDisabled(true)
            setShowActivationBanner(ActivationState.EXISTING_USER)
            fireAnalyticsEvent('userViewedActivationStateExistingUser', {
              copyShownToUser: EMAIL_VERIFICATION_COPY.existingUser
            })
          }
          // auth failure, special combo error instead of 2 frontegg errors
          else if (
            errors.includes(USER_ALREADY_EXISTS) &&
            errors.includes(INCORRECT_EMAIL_OR_PASSWORD)
          ) {
            const productRequestedTruncatedError = ['User already exists, incorrect password']
            setErrorAlert(productRequestedTruncatedError)
            fireAnalyticsEvent('userViewedAuthenticationFormError', {
              copyShownToUser: productRequestedTruncatedError
            })
          }
          // auth failure, pipe any frontegg errors as they are the same as builder login
          else if (errors.length > 0) {
            setErrorAlert(errors)
            fireAnalyticsEvent('userViewedAuthenticationFormError', {
              copyShownToUser: errors
            })
          }
        }

        if (isSignIn) {
          const { errors } = await login({
            values
          })

          // auth success, needs verified, yellow banner
          if (errors.includes(USER_IS_NOT_VERIFIED)) {
            setIsSubmitDisabled(true)
            setShowActivationBanner(ActivationState.EXISTING_USER)
            fireAnalyticsEvent('userViewedActivationStateExistingUser', {
              copyShownToUser: EMAIL_VERIFICATION_COPY.existingUser
            })
          }
          // auth failure, pipe any frontegg errors as they are the same as builder login
          else if (errors.length > 0) {
            setErrorAlert(errors)
            fireAnalyticsEvent('userViewedAuthenticationFormError', {
              copyShownToUser: errors
            })
          }
        }

        if (isFreeQrGeneratorPage) {
          const { user, errors, accessToken } = await signup({
            values,
            product: Domains.FLOWCODE
          })

          // w/ email verification off both new signups and existing users will have an accessToken
          // w/ email verification user.email is returned when an auth transaction does not fail, if errors includes "user is not verified" then auth was successful and they should have their code downloaded
          if (accessToken || (user.email && errors.includes(USER_IS_NOT_VERIFIED))) {
            await handleGeneratorCodeCreation()
          }

          // signup success, needs verified, green banner
          if (user.shouldActivate && user.email) {
            setIsSubmitDisabled(true)
            setResendEmail(user.email)
            setShowActivationBanner(ActivationState.LOCG_SIGNUP)
            fireAnalyticsEvent('userViewedActivationStateLOCGSignup', {
              copyShownToUser: EMAIL_VERIFICATION_COPY.locgSignup
            })
          }
          // auth success, needs verified, yellow banner
          else if (errors.includes(USER_IS_NOT_VERIFIED)) {
            setIsSubmitDisabled(true)
            setShowActivationBanner(ActivationState.EXISTING_USER)
            fireAnalyticsEvent('userViewedActivationStateExistingUser', {
              copyShownToUser: EMAIL_VERIFICATION_COPY.existingUser
            })
          }
          // auth failure, special combo error instead of 2 frontegg errors
          else if (
            errors.includes(USER_ALREADY_EXISTS) &&
            errors.includes(INCORRECT_EMAIL_OR_PASSWORD)
          ) {
            const productRequestedTruncatedError = ['User already exists, incorrect password']
            setErrorAlert(productRequestedTruncatedError)
            fireAnalyticsEvent('userViewedAuthenticationFormError', {
              copyShownToUser: productRequestedTruncatedError
            })
          }
          // auth failure, pipe any frontegg errors as they are the same as builder login
          else if (errors.length > 0) {
            setErrorAlert(errors)
            fireAnalyticsEvent('userViewedAuthenticationFormError', {
              copyShownToUser: errors
            })
          }

          // we force push the user instead of waiting for the useRedirectOnAuthChangesHook to prevent generator abuse
          if (accessToken) {
            push(Routes.HOME)
            return
          }
        }
      } catch (e) {
        logger.error({
          name: 'Auth form submission error',
          message: e,
          technicalArea: 'authentication',
          stack: e.stack
        })
      } finally {
        dispatch(clearAuthFormState())
      }
    },
    [dispatch, isFreeQrGeneratorPage, isSignIn, isSignUp, login, push, signup]
  )
}
