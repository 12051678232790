import { AuthenticationFormFields } from '../../components/Authentication/AuthenticationForm.types'
import { Domains } from '@dtx-company/true-common/src/types/domains'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SignUpLocation, User, signupWithEmailAndPassword } from '@dtx-company/ithaca-sdk/src'
import {
  auth_signup_form_emailpassword_failure,
  auth_signup_form_emailpassword_started,
  auth_signup_form_emailpassword_success
} from '../../constants/authHeapEvents'
import { fireAnalyticsEvent } from '../../event-tracking/helpers/fireAnalyticsEvent'
import { logger } from '@dtx-company/logger'
import { updateHubspotCommunicationPreferences } from '../../utils/notifications'
import { useCohort } from '@dtx-company/inter-app/src/hooks/cohorts/useCohort'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

export interface UseSignupProps {
  values: AuthenticationFormFields
  product: Domains
  shouldForceActivate?: boolean
}

export function useSignup(): ({
  values,
  product,
  shouldForceActivate
}: UseSignupProps) => Promise<{ user: User; errors: string[]; accessToken?: string }> {
  const { name: cohortName } = useCohort()
  const { pathname, query } = useRouter()

  const signupLocation: SignUpLocation = useMemo(() => {
    switch (pathname) {
      case Routes.SIGN_UP:
        return 'signUpPage'
      case Routes.FREE_QR_CODE_GENERATOR:
        return 'loggedOutCodeGenerator'
      case Routes.CONTACT2_FLOWTAG:
        return 'flowtag_claim/contact2'
      case Routes.PAGES:
        return 'flowpage_pages'
      default:
        return 'unknown'
    }
  }, [pathname])

  const canvaSignupDetected = useMemo(() => {
    if (!query.redirectTo || Array.isArray(query.redirectTo) || pathname !== Routes.SIGN_UP) {
      return false
    }

    const [redirectValue, parameters] = query.redirectTo.split('?')
    if (
      redirectValue === Routes.CANVA_REDIRECT &&
      parameters.includes('state=') &&
      parameters.includes('user=')
    ) {
      return true
    }

    return false
  }, [pathname, query.redirectTo])

  return async ({ values, product, shouldForceActivate = false }) => {
    try {
      const { email, noMarketingEmails } = values

      fireAnalyticsEvent(auth_signup_form_emailpassword_started)

      const { errors, shouldActivate, accessToken } = await signupWithEmailAndPassword({
        ...values,
        product,
        cohort: cohortName,
        signupLocation,
        shouldForceActivate: canvaSignupDetected || shouldForceActivate
      })

      if (accessToken) {
        fireAnalyticsEvent(auth_signup_form_emailpassword_success, {
          cohort: cohortName
        })

        if (noMarketingEmails) {
          fireAnalyticsEvent('userOptedOutOfMarketingEmails')
          await updateHubspotCommunicationPreferences({
            email
          })
        }

        // turn this on if re-enabling Flowpage signup onboarding
        // if (pathname === Routes.PAGES && user.email) {
        //   events.userCreatedFlowpageAccount(user.email)
        //   dispatch(checkUserHasReachedMaxPages())
        // }
      }

      const user: User = { email, shouldActivate }

      return { user, errors, accessToken }
    } catch (e) {
      fireAnalyticsEvent(auth_signup_form_emailpassword_failure)

      logger.logError(e, { team: 'PLG' })

      return { user: { email: null, shouldActivate: false }, errors: [] }
    }
  }
}
